import {
  Divider,
  ExpandableSection,
  Grid,
  Heading,
  Form,
  Caption,
  Timeline,
  useToaster,
  Button,
  Spinner,
  Table,
  Card,
} from '@enterprise-ui/canvas-ui-react'
import React, { useState } from 'react'
import { SectionItem } from './SectionItem'
import { EnterpriseIcon, FileIcon, TrashIcon } from '@enterprise-ui/icons'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import moment from 'moment'
import { get } from 'lodash'
import { useAttachment } from '../../api/hooks/useAttachments'
import { formatAttachmentMetadata } from '../submitInvoice/utils'
import { AttachmentStateContext } from '../../context/Attachments/AttachmentContext'
import { useContext } from 'react'
import { getTimelineType } from '../submitInvoice/SubmitInvoiceWrapper'
import { useInvoice } from '../../api/hooks/useInvoice'
import { useAuth } from '@praxis/component-auth'
import { Status } from '../../constants/status'
import { getCurrency } from '../../utils/Formatter'
import { useUserProfile } from '../../context/UserProfile/UserProfileProvider'
import { Security } from '../../constants/SearchFilterInitialValues'
import { filterUser } from '../../utils/SearchUtils'

interface Section {
  label: string
  field: string
  type: 'string' | 'date'
  security: Security
}

const VendorSection: Section[] = [
  {
    label: 'Vendor ID',
    field: 'vendorNumber',
    type: 'string',
    security: Security.BOTH,
  },
  {
    label: 'Vendor Name',
    field: 'vendorName',
    type: 'string',
    security: Security.BOTH,
  },
  {
    label: 'Vendor Type',
    field: 'vendorType',
    type: 'string',
    security: Security.BOTH,
  },
  {
    label: 'Vendor Email',
    field: 'vendorEmailAddress',
    type: 'string',
    security: Security.BOTH,
  },
  {
    label: 'Vendor Contact',
    field: 'vendorContactName',
    type: 'string',
    security: Security.BOTH,
  },
  {
    label: 'EDI Status',
    field: 'ediAndExemptionDetails.ediStatus',
    type: 'string',
    security: Security.EXTERNAL,
  },
]
const DocumentSection: Section[] = [
  {
    label: 'Invoice #',
    field: 'invoiceNumber',
    type: 'string',
    security: Security.BOTH,
  },
  {
    label: 'Purchase Order #',
    field: 'purchaseOrderNumber',
    type: 'string',
    security: Security.BOTH,
  },
  {
    label: 'Location',
    field: 'locationId',
    type: 'string',
    security: Security.BOTH,
  },
  {
    label: 'Department',
    field: 'departmentId',
    type: 'string',
    security: Security.BOTH,
  },
  {
    label: 'Invoice Date',
    field: 'invoiceDate',
    type: 'date',
    security: Security.BOTH,
  },
  {
    label: 'Assigned Team Member',
    field: 'assignedUserName',
    type: 'string',
    security: Security.INTERNAL,
  },
  {
    label: 'Invoice Chargeable',
    field: 'ediAndExemptionDetails.isEdiChargeFeeExempted',
    type: 'string',
    security: Security.INTERNAL,
  },
  {
    label: 'Invoice Exemption Reason',
    field: 'ediAndExemptionDetails.ediChargeFeeExemptionReason',
    type: 'string',
    security: Security.INTERNAL,
  },
]

interface InfoProps {
  invoiceData: any
  setInvcData: any
}
export const InvoiceInfoSection: React.FC<InfoProps> = ({
  invoiceData,
  setInvcData,
}) => {
  const [newAttachments, setNewAttachments] = useState<any[]>([])
  const [userProfile] = useUserProfile()
  const attachments = useContext(AttachmentStateContext)
  const makeToast = useToaster()
  const { session } = useAuth()
  const { upload, retryOnce } = useAttachment()
  const { updateInvoice } = useInvoice()

  const allowanceCostPresent = () => invoiceData?.allowanceCosts?.length >= 1

  const handleAttachments = (files: any) => {
    const currentAttachments: any[] = [...newAttachments]
    for (let i = 0; i < files.length; i++) {
      let duplicate = newAttachments.some(
        (x: any) => x.file?.name === files?.item(i).name
      )
      if (!duplicate) {
        currentAttachments.push({
          file: files?.item(i),
          uploadDate: moment().format('MM/DD/YYYY'),
          isUploaded: false,
          name: files?.item(i).name,
        })
      }
    }
    setNewAttachments([...currentAttachments])
  }

  const removeAttachment = (removedFile: any) => {
    const files = newAttachments.filter((file: any) => file !== removedFile)
    setNewAttachments([...files])
  }

  const handleSave = (updateInv: any) => {
    updateInvoice(invoiceData?.invoiceId, updateInv)
      .then((data) => {
        setInvcData(data)
      })
      .catch(() => {})
  }

  const handleUpdateInvoice = async () => {
    try {
      let attachments = newAttachments.map((attachment) => ({
        name: attachment.name,
        parentId: invoiceData.invoiceId,
        file: attachment.file,
        attachmentMetaData: formatAttachmentMetadata(invoiceData),
        asyncUpload: false,
      }))
      upload(attachments)
      setNewAttachments([])

      let userName = session?.userInfo?.fullName
      let auditEntry = {
        userId: userName,
        activityDescription: 'New documents were uploaded',
        comment: '',
        event: 'UPDATE',
        status: invoiceData?.status,
        activityTimestamp: new Date(),
      }
      let updateInvc = Object.assign({}, invoiceData, {
        status: invoiceData?.status,
        auditEntry: auditEntry,
      })
      handleSave(updateInvc)
    } catch (error: any) {
      let message = error?.response?.data?.message
      makeToast({
        type: 'error',
        message,
        heading: 'Invoice Updation Failed',
      })
      setNewAttachments([])
    }
  }

  return (
    <>
      <ExpandableSection startExpanded padding="dense">
        <Heading size={5} className="hc-fs-md hc-pv-sm">
          Vendor Details
        </Heading>
        <ExpandableSection.Content>
          {VendorSection.map(
            (item) =>
              filterUser(item, userProfile.isVendor) && (
                <SectionItem
                  label={item?.label}
                  key={item?.field}
                  value={
                    item.field === 'ediAndExemptionDetails.ediStatus'
                      ? invoiceData?.ediAndExemptionDetails?.ediStatus ?? 'NA'
                      : invoiceData?.[item.field] != null
                      ? invoiceData[item.field]
                      : 'NA'
                  }
                  type={item?.type}
                />
              )
          )}
        </ExpandableSection.Content>
      </ExpandableSection>
      <Divider className="hc-pv-xs" />
      <ExpandableSection startExpanded padding="dense">
        <Heading size={5} className="hc-fs-md hc-pv-sm">
          Document Details
        </Heading>
        <ExpandableSection.Content>
          {DocumentSection.map(
            (item) =>
              filterUser(item, userProfile.isVendor) && (
                <React.Fragment key={item?.field}>
                  <SectionItem
                    label={item?.label}
                    value={
                      item.field ===
                      'ediAndExemptionDetails.isEdiChargeFeeExempted'
                        ? invoiceData?.ediAndExemptionDetails
                            ?.isEdiChargeFeeExempted
                          ? 'No'
                          : 'Yes'
                        : item.field ===
                          'ediAndExemptionDetails.ediChargeFeeExemptionReason'
                        ? invoiceData?.ediAndExemptionDetails
                            ?.ediChargeFeeExemptionReason ?? 'NA'
                        : invoiceData?.[item.field] != null
                        ? invoiceData[item.field]
                        : 'NA'
                    }
                    type={item?.type}
                  />
                </React.Fragment>
              )
          )}
        </ExpandableSection.Content>
      </ExpandableSection>
      <Divider className="hc-pv-xs" />
      <ExpandableSection startExpanded padding="dense">
        <Heading size={5} className="hc-fs-md hc-pv-sm">
          Invoice Cost Details
        </Heading>
        <ExpandableSection.Content>
          <SectionItem
            label="Product Cost"
            value={invoiceData?.productCost}
            type="amount"
          />
          <SectionItem
            label="Additional Costs & Reductions"
            value={allowanceCostPresent() ? ' ' : ''}
            type="string"
          />
          <br />
          {allowanceCostPresent() && (
            <>
              <Card corners="none">
                <Table>
                  <Table.Head>
                    <Table.Row>
                      <Table.Header xs={6}>Type</Table.Header>
                      <Table.Header xs={4}>
                        Allownace/Charge Amount
                      </Table.Header>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {invoiceData?.allowanceCosts.map(
                      (allowanceCost: any, i: number) => (
                        <Table.Row key={i}>
                          <Table.Data xs={6}>
                            {allowanceCost.allowanceCode}
                          </Table.Data>
                          <Table.Data sortValue="15.2" xs={4}>
                            {getCurrency(allowanceCost.allowanceAmount, 'USD')}
                          </Table.Data>
                        </Table.Row>
                      )
                    )}
                  </Table.Body>
                </Table>
              </Card>
            </>
          )}

          <br />
          <SectionItem
            label="Total Invoice Cost"
            value={invoiceData.invoiceAmount}
            type="amount_strong"
          />
        </ExpandableSection.Content>
      </ExpandableSection>
      <Divider />
      {![Status.INDEXED, Status.REJECTED].includes(invoiceData?.status) && (
        <>
          <Grid.Item>
            <ExpandableSection startExpanded padding="dense">
              <Heading size={5} className="hc-fs-md hc-pv-sm">
                Attachments
              </Heading>
              <ExpandableSection.Content>
                <Form.Field
                  type="drop-area"
                  id="attachments"
                  instructionText={'Drag and Drop Files Here to Upload.'}
                  dropText="Drag and Drop files"
                  fullwidth
                  multiple
                  onUpdate={(e: FormDataEvent) => {
                    const files =
                      get(e, 'dataTransfer.files') || get(e, 'target.files')
                    handleAttachments(files)
                  }}
                ></Form.Field>

                <br />
                <Grid.Container>
                  {newAttachments?.map((file: any) => (
                    <Grid.Item key={file?.file?.id} className="hc-pv-sm">
                      <Caption>
                        <EnterpriseIcon
                          className="dropdownIcon"
                          icon={FileIcon}
                          size="lg"
                          onClick={() => {}}
                        />
                        &nbsp;
                        {file?.file?.name} &emsp;
                        <EnterpriseIcon
                          className="dropdownIcon"
                          icon={TrashIcon}
                          size={'sm'}
                          onClick={() => {
                            removeAttachment(file)
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </Caption>
                    </Grid.Item>
                  ))}
                  {attachments.length > 0 && (
                    <Grid.Item xs={12}>
                      <Timeline>
                        <Timeline.Flag>Updating Attachments</Timeline.Flag>
                        {attachments.map((attachment) => (
                          <Timeline.Item
                            type={getTimelineType(attachment?.status)}
                            key={attachment.name}
                          >
                            {attachment?.status === 'uploading' && (
                              <>
                                <Spinner size="dense" /> &nbsp; Uploading
                                {attachment.name}
                              </>
                            )}
                            {attachment?.status !== 'uploading' && (
                              <p>
                                {attachment.name} &nbsp;
                                {attachment?.status === 'error' && (
                                  <span
                                    className="hc-clr-error"
                                    style={{
                                      textDecoration: 'underline',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      retryOnce(
                                        attachment.name,
                                        attachment.parentId
                                      )
                                    }}
                                  >
                                    retry
                                  </span>
                                )}
                              </p>
                            )}
                          </Timeline.Item>
                        ))}
                      </Timeline>
                    </Grid.Item>
                  )}
                </Grid.Container>
                {newAttachments.length > 0 && (
                  <Grid.Container direction="row-reverse">
                    <Grid.Item className="hc-pv-sm">
                      <Button
                        type="primary"
                        onClick={() => {
                          handleUpdateInvoice()
                        }}
                      >
                        Update
                      </Button>
                    </Grid.Item>
                  </Grid.Container>
                )}
              </ExpandableSection.Content>
            </ExpandableSection>
          </Grid.Item>
          <Divider className="hc-pv-sm" />
        </>
      )}
      {console.log('Invoice data: ', invoiceData)}
      <Grid.Item>
        <ExpandableSection startExpanded padding="dense">
          <Heading size={5} className="hc-fs-md hc-pv-sm">
            Activity Stream
          </Heading>
          <ExpandableSection.Content>
            <Timeline>
              {invoiceData?.auditEntries?.map((entry: any, i: any) => (
                <TimelineItem i={i} entry={entry} userProfile={userProfile} />
              ))}
            </Timeline>
          </ExpandableSection.Content>
        </ExpandableSection>
        <Divider className="hc-pv-sm hc-pb-4x" />
      </Grid.Item>
    </>
  )
}

const TimelineItem = ({ entry, userProfile, i }: any) => {
  const renderDate = () => (
    <p>
      <DateFormatter date={entry?.activityTimestamp} format="MM-DD-YYYY" />
    </p>
  )

  // activity description
  const getActivityDescription = () => {
    if (
      userProfile.isVendor &&
      entry?.activityDescription.includes('Invoice was assigned')
    ) {
      return 'Invoice was assigned'
    }
    return entry?.activityDescription
  }

  // user ID if not a vendor
  const appendUserId = () => {
    return !userProfile.isVendor ? ` by ${entry?.userId}` : ''
  }

  // comments if they exist
  const appendComments = () => {
    return entry?.comment ? ` with comments "${entry?.comment}"` : ''
  }

  return (
    <Timeline.Item key={i} type="success">
      <Caption className="hc-fs-sm" above={renderDate()}>
        {getActivityDescription()}
        {appendUserId()}
        {appendComments()}
      </Caption>
    </Timeline.Item>
  )
}

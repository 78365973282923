import {
  Form,
  Modal,
  Grid,
  Button,
  Spinner,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { useVendorApi } from '../../api/hooks/useVendorApi'
import { ExemptionVendorForm, isCreateView } from './utils'
import { useExemptionVendor } from '../../api/hooks/useExemptionVendor'

interface ExemptionVendorViewProps {
  setShowCreateScreen: (flag: boolean) => void
  showCreateScreen: boolean
  exemptionId: number
}

interface Loader {
  view: boolean
  vendor: boolean
}

export const ExemptionVendorView: React.FC<ExemptionVendorViewProps> = ({
  setShowCreateScreen,
  showCreateScreen,
  exemptionId,
}) => {
  const formik = useFormikContext<ExemptionVendorForm>()
  const { getVendorDetails } = useVendorApi()
  const { getVendorDetailsFromId } = useExemptionVendor()
  const makeToast = useToaster()

  const {
    values,
    errors,
    setFieldValue,
    setFieldError,
    isValid,
    handleChange,
    handleSubmit,
    dirty,
    resetForm,
  } = formik
  const [loader, setLoader] = useState<Loader>({
    view: false,
    vendor: false,
  })
  const [enableDateField, setEnableDateField] = useState<boolean>(false)
  const [fieldDisabled, setFieldDisabled] = useState<boolean>(false)

  const vendorNumberOnChange = (event: any) => {
    setFieldValue('vendorNumber', event.target.value).then((r) => {
      setLoader({ ...loader, vendor: true })
      getVendorDetails(
        isCreateView(exemptionId) ? event.target.value : exemptionId
      )
        .then((response: any) => {
          setFieldValue('vendorName', response.vendorName.trim()).then(() => {
            setLoader({ ...loader, vendor: false })
          })
        })
        .catch((error: any) => {
          setFieldValue('vendorName', '').then((r) => {
            if (error.response.status === 404) {
              setFieldError(
                'vendorNumber',
                'vendor not found for the given number'
              )
            } else if (error.response.status === 400) {
              setFieldError(
                'vendorNumber',
                'Please enter a valid vendor number.'
              )
            } else {
              setFieldError(
                'vendorNumber',
                'failed to fetch the vendor info. please try again'
              )
            }
            setLoader({ ...loader, vendor: false })
          })
        })
    })
  }

  const permanentExemptOnChange = (event: any) => {
    setEnableDateField(!event.target.checked)
    setFormikFieldValue('permanentExemptFlag', event.target.checked)
  }

  const setFormikFieldValue = (fieldName: string, fieldValue: any) => {
    setFieldValue(fieldName, fieldValue).then(() => {})
  }

  const fetchVendorDetailsById = (vendorNumber: number) => {
    setLoader({ ...loader, view: true })
    getVendorDetailsFromId(vendorNumber)
      .then((response: any) => {
        setFormikFieldValue('vendorNumber', parseInt(response.vendorNumber))
        setFormikFieldValue('vendorName', response.vendorName)
        setFormikFieldValue('active', response.active)
        setFormikFieldValue('permanentExemptFlag', response.permanentExemptFlag)
        setFormikFieldValue('exemptStartDate', response.exemptStartDate)
        setFormikFieldValue('exemptEndDate', response.exemptEndDate)

        if (response.permanentExemptFlag) {
          setEnableDateField(false)
        } else {
          setEnableDateField(true)
        }
        setLoader({ ...loader, view: false })
      })
      .catch((error: any) => {
        console.log('fetch vendor details failed: ' + error.response)
        setLoader({ ...loader, view: false })
        onCancel()
        makeToast({
          autoHideDuration: 5000,
          type: 'error',
          heading: 'Vendor info fetch failed',
          message: 'Failed to fetch the vendor information. Please try again',
        })
      })
  }

  const onCancel = () => {
    setShowCreateScreen(false)
  }

  useEffect(() => {
    if (showCreateScreen) {
      resetForm()
      if (isCreateView(exemptionId)) {
        // create vendor view
        setFieldDisabled(false)
        setEnableDateField(false)
      } else {
        // update vendor view
        setFieldDisabled(true)
        fetchVendorDetailsById(exemptionId)
      }
    }
  }, [showCreateScreen, exemptionId]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      isVisible={showCreateScreen}
      onRefuse={onCancel}
      headingText={isCreateView(exemptionId) ? 'Add Vendor' : 'Edit Vendor'}
    >
      <Grid.Container justify={'center'} className="hc-pa-normal">
        {loader.view && (
          <>
            <Grid.Item xs={1}>
              <Spinner />
            </Grid.Item>
          </>
        )}
        {!loader.view && (
          <>
            <Grid.Item xs={11}>
              <Grid.Container>
                <Grid.Item xs={4}>
                  <Form.Field
                    type="text"
                    label="Enter the Vendor Number"
                    id="vendorNumber"
                    name="vendorNumber"
                    value={values.vendorNumber}
                    onChange={vendorNumberOnChange}
                    error={!!errors.vendorNumber}
                    errorText={errors.vendorNumber}
                    disabled={fieldDisabled}
                    required
                  />
                </Grid.Item>
                <Grid.Item xs={5}>
                  <Form.Field
                    type="text"
                    label="Vendor Name"
                    id="vendorName"
                    name="vendorName"
                    value={values.vendorName}
                    required
                    disabled
                  />
                </Grid.Item>
                {loader.vendor && (
                  <Grid.Item xs={2} className={'margin-top-20'}>
                    <Spinner />
                  </Grid.Item>
                )}
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xs={11}>
              <Grid.Container align="center">
                <Grid.Item xs={5}>
                  <Form.Field
                    type="toggle"
                    label="Permanent Exemption"
                    id="permanentExempt"
                    name="permanentExempt"
                    onChange={permanentExemptOnChange}
                    value={values.permanentExemptFlag}
                    required
                  />
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            {enableDateField && (
              <Grid.Item xs={11}>
                <Grid.Container align="center">
                  <Grid.Item xs={5}>
                    <Form.Field
                      type="date"
                      label="Exemption Beginning"
                      id="exemptStartDate"
                      name="exemptStartDate"
                      onChange={handleChange}
                      value={values.exemptStartDate}
                      error={!!errors.exemptStartDate}
                      errorText={errors.exemptStartDate}
                      min={new Date().toISOString().split('T')[0]}
                    />
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Form.Field
                      type="date"
                      label="Exemption Terminating"
                      id="exemptEndDate"
                      name="exemptEndDate"
                      onChange={handleChange}
                      value={values.exemptEndDate}
                      error={!!errors.exemptEndDate}
                      errorText={errors.exemptEndDate}
                      min={new Date().toISOString().split('T')[0]}
                    />
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
            )}
            <Grid.Item xs={12}>
              <Grid.Container direction="row-reverse" spacing="dense">
                <Grid.Item>
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={!isValid || !dirty}
                  >
                    Submit
                  </Button>
                </Grid.Item>
                <Grid.Item>
                  <Button onClick={onCancel}>Cancel</Button>
                </Grid.Item>
                <Grid.Item></Grid.Item>
                <Grid.Item>
                  <Form.Field
                    id="active"
                    name="active"
                    label="Active"
                    onChange={handleChange}
                    value={values.active}
                    type="toggle"
                    required
                  />
                </Grid.Item>
              </Grid.Container>
              <Grid.Container spacing="dense"></Grid.Container>
            </Grid.Item>
          </>
        )}
      </Grid.Container>
    </Modal>
  )
}

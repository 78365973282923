import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { SubmitInvoiceForm } from './SubmitInvoiceForm'
import { Formik, FormikProps } from 'formik'
import { useInvoice } from '../../api/hooks/useInvoice'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAttachment } from '../../api/hooks/useAttachments'
import {
  Button,
  Drawer,
  Grid,
  GridContainer,
  GridItem,
  Heading,
  Spinner,
  Timeline,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { formatAttachmentMetadata, formatCreateInvoice } from './utils'
import { Status } from '../../constants/status'
import { InvoiceResponse } from '../../api/models/InvoiceResponse'
import { useDashboard } from '../../context/Invoices/DashboardProvider'
import { InvoiceForm, SubmitInvoiceSchema } from './SubmitInvoiceSchema'

export function getTimelineType(
  status: 'pending' | 'uploading' | 'completed' | 'error' | 'cancelled'
): 'success' | 'error' | 'default' {
  switch (status) {
    case 'completed':
      return 'success'
    case 'error':
      return 'error'
    default:
      return 'default'
  }
}

const initialInvoiceValues: InvoiceForm = {
  invoiceNumber: '',
  vendorNumber: 0,
  vendorName: '',
  vendorContactName: '',
  vendorEmailAddress: '',
  vendorType: '',
  purchaseOrderNumber: 0,
  locationId: '',
  departmentId: 0,
  invoiceDate: '',
  productCost: 0,
  invoiceAmount: 0,
  allowanceAmount: 0,
  attachments: [],
  isPOValid: false,
  allowanceCosts: undefined,
}

type SubmitInvoiceWrapperProps = {
  isDrawerOpen: boolean
  setIsDrawerOpen: (isDrawerOpen: boolean) => void
}

export const SubmitInvoiceWrapper: React.FC<SubmitInvoiceWrapperProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
}) => {
  const navigate = useNavigate()
  const makeToast = useToaster()
  const {
    upload,
    addAttachments,
    getAttachmentsByParentId,
    removeAttachments,
    retryOnce,
  } = useAttachment()
  const { postInvoice, getInvoicesForAppliedFilters } = useInvoice()
  const { isLoading, data, mutateAsync } = useMutation({
    mutationKey: ['postInvoice'],
    mutationFn: postInvoice,
  })
  const client = useQueryClient()
  const [, dispatch] = useDashboard()

  const attachments = useMemo(
    () => getAttachmentsByParentId(data?.invoiceId.toString()),
    [data, getAttachmentsByParentId]
  )

  const [formState, setFormState] = useState<{
    invoiceId?: string
    attachmentUploadView: boolean
    attachmentStatus: boolean
  }>({ attachmentUploadView: false, attachmentStatus: false })

  useEffect(() => {
    if (
      attachments.length > 0 &&
      attachments.every((attachment) => attachment?.status === 'completed')
    ) {
      setFormState((state) => ({ ...state, attachmentStatus: false }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments])

  async function checkDuplicates(values: InvoiceForm): Promise<boolean> {
    // in app duplicate
    const searchResponse = await client.fetchQuery({
      queryKey: ['getInvoice'],
      queryFn: () =>
        getInvoicesForAppliedFilters({
          vendorNumber: values.vendorNumber,
          invoiceNumber: values.invoiceNumber,
          page: 1,
          perPage: 100,
        }),
    })

    if (searchResponse?.totalCount > 0) {
      // check if any not rejected
      const nonRejectedInvoices = searchResponse?.invoiceResponseList.filter(
        (invoice: InvoiceResponse) => invoice.status !== Status.REJECTED
      )
      if (nonRejectedInvoices.length > 0) {
        const invoiceId = searchResponse?.invoiceResponseList[0].invoiceId
        makeToast({
          message: `Invoice with Vendor:${values.vendorNumber} and Invoice Number:${values.invoiceNumber} is already open`,
          heading: 'Duplicate Invoice Submission',
          type: 'alert',
          autoHideDuration: 100000,
          links: [
            {
              text: 'Click here to View Invoice',
              onClick: () => {
                navigate(`/view/${invoiceId}`)
              },
            },
          ],
        })

        return true
      }
      return false
    }

    // tap duplicate

    return false
  }

  const invoiceCloseHandler = (props: FormikProps<InvoiceForm>) => {
    props.resetForm()
    setFormState((state) => ({
      ...state,
      attachmentUploadView: false,
    }))
    removeAttachments(formState.invoiceId ?? '')
    setIsDrawerOpen(false)
  }

  const newInvoiceHandler = (props: FormikProps<InvoiceForm>) => {
    props.resetForm()
    setFormState((state) => ({
      ...state,
      attachmentUploadView: false,
    }))
    removeAttachments(formState.invoiceId ?? '')
  }

  const handleSubmitInvoice = async (
    submitType: 'submit' | 'submit-new',
    formikProps: FormikProps<InvoiceForm>
  ) => {
    const { values, submitForm, setSubmitting, isValid, resetForm } =
      formikProps

    await submitForm()

    if (!isValid) return

    const duplicate = await checkDuplicates(values)

    if (!duplicate) {
      try {
        const invoice = await mutateAsync(formatCreateInvoice(values))
        makeToast({
          type: 'success',
          message: 'Invoice submitted successfully!',
          heading: 'Success',
          autoHideDuration: 5000,
        })
        if (submitType === 'submit-new') {
          let attachments = values.attachments.map((attachment) => ({
            name: attachment.name,
            parentId: invoice.invoiceId.toString(),
            file: attachment.file,
            attachmentMetaData: formatAttachmentMetadata(invoice),
            asyncUpload: true,
          }))
          addAttachments(attachments)
          resetForm()
        } else {
          let attachments = values.attachments.map((attachment) => ({
            name: attachment.name,
            parentId: invoice.invoiceId.toString(),
            file: attachment.file,
            attachmentMetaData: formatAttachmentMetadata(invoice),
            asyncUpload: false,
          }))
          upload(attachments)
          setFormState((state) => ({
            ...state,
            invoiceId: invoice.invoiceId.toString(),
            attachmentUploadView: true,
          }))
        }
      } catch (error: any) {
        let message = error?.response?.data?.message
        makeToast({
          type: 'error',
          message,
          heading: 'Invoice Submission Failed',
        })
      } finally {
        setSubmitting(false)
        dispatch({ type: 'RELOAD_INVOICES', isReload: true })
      }
    }
    setSubmitting(false)
  }

  return (
    <>
      <Formik
        initialValues={initialInvoiceValues}
        validationSchema={SubmitInvoiceSchema}
        validateOnBlur={true}
        onSubmit={() => {}}
        initialTouched={true}
      >
        {(props) => (
          <Drawer
            isVisible={isDrawerOpen}
            onRequestClose={() => {
              invoiceCloseHandler(props)
            }}
            headingText="Submit a Invoice"
            xs={6}
          >
            <div>
              {!formState.attachmentUploadView && (
                <SubmitInvoiceForm handleSubmitInvoice={handleSubmitInvoice} />
              )}
              {formState.attachmentUploadView && data && (
                <GridContainer className={'hc-pa-md'} align="center">
                  <GridItem xs={12}>
                    <Heading size={5}> Invoice Submission Status</Heading>
                  </GridItem>
                  <GridItem xs={12}>
                    <Timeline>
                      <Timeline.Item type={isLoading ? 'default' : 'success'}>
                        {isLoading && <p>Submitting the Invoice</p>}
                        {!isLoading && (
                          <p>Submitted the Invoice with ID: {data.invoiceId}</p>
                        )}
                      </Timeline.Item>

                      <Timeline.Flag>Uploading Attachments</Timeline.Flag>
                      {attachments.map((attachment) => (
                        <Timeline.Item
                          type={getTimelineType(attachment?.status)}
                        >
                          {attachment?.status === 'uploading' && (
                            <>
                              <Spinner size="dense" /> &nbsp; Uploading{' '}
                              {attachment.name}
                            </>
                          )}
                          {attachment?.status !== 'uploading' && (
                            <p>
                              {attachment.name} &nbsp;
                              {attachment?.status === 'error' && (
                                <span
                                  className="hc-clr-error"
                                  style={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    retryOnce(
                                      attachment.name,
                                      attachment.parentId
                                    )
                                  }}
                                >
                                  retry
                                </span>
                              )}
                            </p>
                          )}
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  </GridItem>
                  <GridItem xs={12}>
                    <Grid.Container>
                      <Grid.Item xs={12}>
                        <Heading size={5}>
                          Do you want to submit another request?
                        </Heading>
                      </Grid.Item>
                      <Grid.Item>
                        <Button
                          type="secondary"
                          onClick={() => {
                            newInvoiceHandler(props)
                          }}
                        >
                          Yes
                        </Button>
                      </Grid.Item>
                      <Grid.Item>
                        <Button
                          type="primary"
                          onClick={() => {
                            invoiceCloseHandler(props)
                          }}
                        >
                          No Thanks
                        </Button>
                      </Grid.Item>
                    </Grid.Container>
                  </GridItem>
                  <GridItem></GridItem>
                </GridContainer>
              )}
            </div>
          </Drawer>
        )}
      </Formik>
    </>
  )
}

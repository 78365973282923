import React, { useEffect, useState } from 'react'
import { DateRange, Value } from '../../../constants/SearchFilterInitialValues'
import { Button, Popover, Grid } from '@enterprise-ui/canvas-ui-react'
import { convertToISO, convertToUS } from '../../../utils/SearchUtils'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-datepicker'
import _ from 'lodash'

interface FilterDateRangeProps {
  id: string
  values: Value[]
  activeFilter: DateRange
  onChange: any
}

export const FilterDateRange: React.FC<FilterDateRangeProps> = ({
  id,
  values,
  activeFilter,
  onChange,
}) => {
  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()
  const [isVisible, setVisible] = useState<boolean>()

  useEffect(() => {
    const activeFilterValue = _.filter(values, function (n) {
      return JSON.stringify(n.value) === JSON.stringify(activeFilter)
    })

    if (activeFilter && activeFilterValue.length < 1) {
      setStartDate(convertToUS(activeFilter.from))
      setEndDate(convertToUS(activeFilter.to))
    }
  }, [activeFilter, values])

  return (
    <React.Fragment>
      <div className="hc-pa-normal hc-ph-none">
        <Popover
          type="clickOnly"
          isVisible={isVisible}
          location="bottom-right"
          content={
            <div>
              <DatePicker
                id={`${id}_start_date`}
                type="retail-range"
                label="Enter Date Range"
                onUpdate={(id, value) => {
                  setStartDate(value.startDate.value)
                  setEndDate(value.endDate.value)
                }}
                location="bottom-right"
                className="date-picker"
              />
              <Grid.Container>
                <Grid.Item className="hc-pr-none">
                  <Button
                    type="secondary"
                    onClick={() => {
                      onChange(id, {
                        from: convertToISO(startDate!!),
                        to: convertToISO(endDate!!),
                      })
                      setVisible(false)
                    }}
                    disabled={_.isEmpty(startDate) || _.isEmpty(endDate)}
                  >
                    Apply
                  </Button>
                </Grid.Item>
                <Grid.Item className="hc-pl-none">
                  <Button
                    type="ghost"
                    onClick={() => {
                      setStartDate('')
                      setEndDate('')
                      setVisible(false)
                    }}
                  >
                    Cancel
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </div>
          }
        >
          <Button type="primary" onClick={() => setVisible(true)}>
            Select Date Range
          </Button>
        </Popover>
      </div>
    </React.Fragment>
  )
}

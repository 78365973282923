import {
  Button,
  Grid,
  Heading,
  Input,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import {
  AuditEntry,
  MultipleExemptionModel,
} from '../../api/models/InvoiceResponse'
import { useAuth } from '@praxis/component-auth'
import { useState } from 'react'

interface ExemptMultipleModalProps {
  visible: boolean
  invoices: any
  handleExempt: (multipleExemptionModel: any) => void
  handleClose: () => void
  isLoading: boolean
}

export const ExemptMultipleModal: React.FC<ExemptMultipleModalProps> = ({
  visible,
  invoices,
  handleExempt,
  handleClose,
  isLoading,
}) => {
  const { session } = useAuth()
  const [comments, setComments] = useState<string>('')
  const ids = invoices.map((invoice: any) => invoice.invoiceId)
  const lanId = session?.userInfo?.lanId
  const newAuditEntry: AuditEntry = {
    userId: lanId,
    activityDescription:
      'Exempted Invoice from Charge Fee with comments: ' + comments + ', ',
    event: 'EXEMPT',
  }

  const multipleExemptionModel: MultipleExemptionModel = {
    invoiceIds: ids,
    ediChargeFeeExemptionComment: comments,
    auditEntry: newAuditEntry,
  }

  const checkReason = () => {
    if (!comments) {
      return true
    } else return false
  }
  return (
    <Modal
      isVisible={visible}
      size="dense"
      headingText="Confirm Exemption"
      onRefuse={() => {
        handleClose()
        setComments('')
      }}
      style={{ overflowY: 'visible' }}
    >
      <Grid.Container className="hc-pa-md">
        <Grid.Item xs={12}>
          <Heading size={5}>
            Are you sure you want to exempt these invoices from the charge fee?
          </Heading>
        </Grid.Item>
        <Grid.Item xs={12}>
          <Input.Label htmlFor="claim_reopen_reason_label" required>
            Enter the reason for exempting the invoice from the charge fee:
          </Input.Label>
          <Input.Textarea
            id="exempt_comments"
            required
            onChange={(event: any) => {
              setComments(event.target.value)
            }}
            value={comments}
          />
        </Grid.Item>
        <Grid.Container direction="row-reverse" className="hc-pr-md hc-pb-md">
          <Grid.Item>
            <Button
              type="primary"
              onClick={() => {
                handleExempt(multipleExemptionModel)
                setComments('')
              }}
              isLoading={isLoading}
              disabled={checkReason()}
            >
              Yes
            </Button>
          </Grid.Item>
          <Grid.Item>
            <Button
              type="secondary"
              onClick={() => {
                handleClose()
                setComments('')
              }}
            >
              Cancel
            </Button>
          </Grid.Item>
        </Grid.Container>
      </Grid.Container>
    </Modal>
  )
}

import { array, boolean, InferType, number, object, string } from 'yup'

export const SubmitInvoiceSchema = object({
  invoiceNumber: string()
    .required('Invoice number is mandatory')
    .min(4, 'Invoice length should be a minimum of 4 characters')
    .max(20, 'Invoice length should be a maximum of 20 characters'),
  vendorNumber: number()
    .required('Please select a valid vendor number')
    .positive('Please select a valid vendor number'),
  vendorName: string().required('Vendor name is required'),
  vendorContactName: string().required('Vendor contact name is required'),
  vendorEmailAddress: string()
    .required('Vendor email address is required')
    .email('Not a valid email'),
  vendorType: string().optional(),
  purchaseOrderNumber: number()
    .required('Purchase order is mandatory')
    .test(
      'valid-purchase-order',
      'Invalid purchase order number',
      function (value) {
        const isWithinRange = value >= 1 && value <= 9999999
        const hasElevenDigits = value.toString().length === 11
        const startsWith1000 = value.toString().startsWith('1000')

        return isWithinRange || (hasElevenDigits && startsWith1000)
      }
    ),
  locationId: string()
    .required('Location ID is mandatory')
    .min(1, 'Invalid location ID')
    .max(4, 'Invalid location ID'),
  departmentId: number()
    .required('Department is mandatory')
    .moreThan(1, 'Invalid department')
    .lessThan(999, 'Invalid department'),
  invoiceDate: string()
    .required('Invoice date is required')
    .test(
      'valid-invoice-date',
      'Invoice date should be within the last 18 months',
      function (value) {
        if (!value) return false
        const invoiceDate = new Date(value)
        const currentDate = new Date()

        const eighteenMonthsAgo = new Date()
        eighteenMonthsAgo.setMonth(currentDate.getMonth() - 18)
        return invoiceDate.getTime() >= eighteenMonthsAgo.getTime()
      }
    ),
  productCost: number()
    .required('Product cost is required')
    .moreThan(0, 'Product cost should be greater than 0'),
  allowanceCosts: array()
    .optional()
    .of(
      object({
        allowanceType: string().required('Allowance type is required'),
        allowanceCode: string().required('Allowance code is required'),
        allowanceAmount: number()
          .required('Allowance amount is required')
          .moreThan(0, 'Allowance amount should be greater than 0'),
      })
    )
    .max(5, 'Maximum 5 allowances are allowed'),
  invoiceAmount: number()
    .required('Invoice amount is required')
    .moreThan(0, 'Invoice amount should be greater than 0'),
  allowanceAmount: number().required('Allowance amount is required'),
  attachments: array()
    .required('At least one attachment is required')
    .min(1, 'Minimum one attachment required'),
  isPOValid: boolean().defined(),
})

export interface InvoiceForm extends InferType<typeof SubmitInvoiceSchema> {}

import { InferType } from 'yup'
import * as Yup from 'yup'

export interface ExemptionVendorForm extends InferType<typeof addSchema> {}

export const addSchema = Yup.object({
  permanentExemptFlag: Yup.boolean().required(),
  vendorName: Yup.string().optional(),
  vendorNumber: Yup.number().required('Please enter a valid vendor number.'),

  exemptStartDate: Yup.string().when(
    'permanentExemptFlag',
    (permanentExemptFlagList, schema) => {
      if (!permanentExemptFlagList.at(0)) {
        return schema.required(
          'start date must not be blank when permanent exempt is disabled'
        )
      } else {
        return schema.notRequired()
      }
    }
  ),

  exemptEndDate: Yup.string()
    .when('permanentExemptFlag', (permanentExemptFlagList, schema) => {
      if (!permanentExemptFlagList.at(0)) {
        return schema.required(
          'end date must not be blank when permanent exempt is disabled'
        )
      } else {
        return schema.notRequired()
      }
    })
    .test(
      'valid-exemptEnd-date',
      'Exempt End Date should be after the Start Date',
      function (value) {
        const { exemptStartDate } = this.parent

        // If both dates are not provided, skip validation
        if (!value || !exemptStartDate) {
          return true
        }

        const startDate = new Date(exemptStartDate)
        const endDate = new Date(value)

        // Return true if the end date is after the start date
        return endDate > startDate
      }
    ),

  active: Yup.boolean().required(),
})

export const initialFormValues: ExemptionVendorForm = {
  vendorNumber: 0,
  vendorName: '',
  active: true,
  permanentExemptFlag: true,
  exemptStartDate: '',
  exemptEndDate: '',
}

export const isCreateView = (vendorId: number) => {
  return vendorId === 0
}

import { createContext } from 'react'
import { DashboardCard } from '../../constants/DashboardCards'
import { InvoiceResponse } from '../../api/models/InvoiceResponse'
import { SearchFilterProp } from '../../constants/SearchFilterInitialValues'

export type DashboardAction =
  | {
      type: 'SET_ACTION'
      actionVisible: boolean
    }
  | {
      type: 'SET_CARD_DATA'
      cards?: DashboardCard[]
    }
  | {
      type: 'SET_TABLE_DATA'
      data: InvoiceResponse[]
    }
  | {
      type: 'SET_FILTERS'
      filters: SearchFilterProp
    }
  | {
      type: 'UPDATE_FILTERS'
      activeFilters: any
    }
  | {
      type: 'SET_DEFAULT_SIDE_FILTERS_VALUES'
      values: any
    }
  | {
      type: 'RELOAD_INVOICES'
      isReload: boolean
    }

export interface DashboardState {
  cards?: DashboardCard[]
  invoiceData?: InvoiceResponse[]
  filters?: SearchFilterProp
  actionVisible?: boolean
  isReload?: boolean
}

const DashboardStateContext = createContext<DashboardState>({
  actionVisible: false,
  isReload: false,
})
DashboardStateContext.displayName = 'DashboardStateContext'

const DashboardDispatchContext = createContext<React.Dispatch<DashboardAction>>(
  () => null
)
DashboardDispatchContext.displayName = 'DashboardDispatchContext'

export { DashboardStateContext, DashboardDispatchContext }

import * as qs from 'qs'
import _ from 'lodash'
import { Location } from 'react-router-dom'
import { Security } from '../constants/SearchFilterInitialValues'
import { DashboardState } from '../context/Invoices/DashboardContext'
import moment from 'moment'

const convertToUS = (isoDate: string | undefined) => {
  const split = isoDate?.split('-')

  if (split && split.length > 1) {
    return `${split[1]}/${split[2]}/${split[0]}`
  }
  return undefined
}

const convertToISO = (usDate?: string) => {
  const split = usDate?.split('/')

  if (split && split.length > 1) {
    return `${split[2]}-${split[0]}-${split[1]}`
  } else {
    return usDate
  }
}

function filterUser(key: any, isVendor?: Boolean): Boolean {
  if (isVendor) {
    return key.security === Security.EXTERNAL || key.security === Security.BOTH
  }
  return key.security === Security.INTERNAL || key.security === Security.BOTH
}

function buildUrl(history: Location, payload: any): string {
  let queryObject: any = qs.parse(history.search.replace('?', ''))

  const { filter, searchField, event, type } = payload

  //radio
  if (type === 'radio') {
    queryObject[filter] = event
  }

  if (type === 'search_group') {
    if (queryObject[searchField] === undefined) {
      queryObject[searchField] = [...event.id.split(',')]
    } else {
      queryObject[searchField] = _.uniq([
        ...(queryObject[searchField] ?? []),
        ...event.id.split(','),
      ])
    }
  }

  // checkbox
  if (type === 'checkbox') {
    if (queryObject[filter] === undefined) {
      queryObject[filter] = [event.target.value]
    } else {
      const filters = Object.values(queryObject[filter])
      const value = event.target.value
      if (filters.length === _.pull(filters, value).length) {
        filters.push(value)
      }
      queryObject[filter] = [...filters]
    }
  }

  // date ranges
  if (type === 'date') {
    queryObject[filter] = event
  }

  if (type === 'dateRange') {
    queryObject[filter] = event
  }

  // main search
  if (type === 'search') {
    if (queryObject['search'] == null) {
      queryObject['search'] = {}
    }
    if (queryObject['search'][payload.id] == null) {
      let obj: any = queryObject['search'] ?? {}
      obj[payload.id] = [payload.value]
      queryObject['search'] = obj
    } else {
      queryObject['search'][payload.id] = _.uniq([
        ...queryObject['search'][payload.id],
        payload.value,
      ])
    }
  }
  return qs.stringify(queryObject)
}

function removeFilter(history: Location, payload: any): string {
  let queryObject: any = qs.parse(history.search.replace('?', ''), {
    arrayLimit: 100,
  })
  const { type, filter, id } = payload

  Object.keys(queryObject)
    .filter((key) => key === filter)
    .forEach((key) => {
      if (type === 'checkbox' || type === 'search') {
        _.remove(queryObject[key], function (n) {
          return n === id
        })
      }
      if (type === 'searchFilter') {
        _.remove(queryObject[key][id], function (n) {
          return n === payload.value
        })
      }
      if (type === 'date' || type === 'radio' || type === 'dateRange') {
        queryObject[key] = undefined
      }

      if (type === 'search_group') {
        _.remove(queryObject[key], function (n) {
          return n === id
        })
      }
    })
  return qs.stringify(queryObject)
}

function handleDateRangeFilter(dateRange: any) {
  if (dateRange === 'now') {
    return moment().format('YYYY-MM-DD')
  }
  if (dateRange === 'now-7d') {
    return moment().subtract(7, 'days').format('YYYY-MM-DD')
  }
  if (dateRange === 'now-14d') {
    return moment().subtract(14, 'days').format('YYYY-MM-DD')
  }
  if (dateRange === 'now-30d') {
    return moment().subtract(30, 'days').format('YYYY-MM-DD')
  }
  return dateRange
}
function buildQuery(history: Location, dashboard: DashboardState, lanId?: any) {
  let obj: any = qs.parse(history.search.replace('?', '')) //TODO need review
  if (obj['search'] != null) {
    obj = { ...obj, ...obj['search'] }
    delete obj['search']
  }

  if (
    obj['invoice_assignment'] != null &&
    obj['invoice_assignment'].includes('Invoices Assigned to Me')
  ) {
    obj['assignedUserId'] = lanId
    delete obj['invoice_assignment']
  }
  if (
    obj['chargeable'] != null &&
    obj['chargeable'].includes('Invoices Chargeable')
  ) {
    obj['isEdiChargeFeeExempted'] = false
    delete obj['chargeable']
  }
  if (
    obj['chargeable'] != null &&
    obj['chargeable'].includes('Invoices Not Chargeable')
  ) {
    obj['isEdiChargeFeeExempted'] = true
    delete obj['chargeable']
  }
  if (
    obj['chargeable'] != null &&
    obj['chargeable'].includes('Invoices Not Chargeable') &&
    obj['chargeable'].includes('Invoices Chargeable')
  ) {
    obj['isEdiChargeFeeExempted'] = 'true,false'
    delete obj['chargeable']
  }
  if (obj['invoiceDate'] != null) {
    obj['invoiceDateFrom'] = handleDateRangeFilter(obj['invoiceDate']['from'])
    obj['invoiceDateTo'] = handleDateRangeFilter(obj['invoiceDate']['to'])
    delete obj['invoiceDate']
  }

  if (obj['createDate'] != null) {
    obj['createDateFrom'] = handleDateRangeFilter(obj['createDate']['from'])
    obj['createDateTo'] = handleDateRangeFilter(obj['createDate']['to'])
    delete obj['createDate']
  }

  if (obj['chargeFeeReport'] != null) {
    obj['createDateFrom'] = handleDateRangeFilter(
      obj['chargeFeeReport']['from']
    )
    obj['createDateTo'] = handleDateRangeFilter(obj['chargeFeeReport']['to'])
    delete obj['chargeFeeReport']
  }

  if (obj['updateDate'] != null) {
    obj['updateDateFrom'] = handleDateRangeFilter(obj['updateDate']['from'])
    obj['updateDateTo'] = handleDateRangeFilter(obj['updateDate']['to'])
    delete obj['updateDate']
  }

  return obj
}

export {
  convertToISO,
  convertToUS,
  filterUser,
  removeFilter,
  buildQuery,
  buildUrl,
}

//from url populate activefilters
//from url make api call
//serach filter

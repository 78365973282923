export enum Security {
  INTERNAL,
  EXTERNAL,
  BOTH,
}

export interface DateRange {
  from: string
  to: string
}

export interface Value {
  id: string
  label: string
  value: string | DateRange
  isChecked?: boolean
}

const dateFilters: Value[] = [
  {
    id: '7d',
    label: 'Last 7 days',
    value: {
      from: 'now-7d',
      to: 'now',
    },
  },
  {
    id: '30d',
    label: 'Last 30 Days',
    value: {
      from: 'now-30d',
      to: 'now',
    },
  },
  {
    id: '7-14d',
    label: 'Between 7 to 14 Days',
    value: {
      from: 'now-14d',
      to: 'now-7d',
    },
  },
]

export interface SearchFilterProp {
  searchFilters?: any
  loading?: boolean
  sideFilters?: SideFilterProp[]
}

export interface SearchFieldProp {
  field: string
  placeHolder: string
  label: string
  values: any[]
}

export interface SideFilterProp {
  id: string
  displayName: string
  security: Security
  values?: Value[]
  type: 'checkbox' | 'search_group' | 'date' | 'radio' | 'dateRange'
  startExpanded?: boolean
  active?: string | boolean | number | any
  searchFields?: SearchFieldProp[]
}

export const initialValue: SearchFilterProp = {
  searchFilters: [],
  loading: false,
  sideFilters: [
    {
      id: 'status',
      displayName: 'Status',
      security: Security.BOTH,
      values: [
        {
          id: 'New',
          value: 'New',
          label: 'New',
          isChecked: false,
        },
        {
          id: 'Indexed',
          value: 'Indexed',
          label: 'Indexed',
          isChecked: false,
        },
        {
          id: 'Rejected',
          value: 'Rejected',
          label: 'Rejected',
          isChecked: false,
        },
      ],
      type: 'checkbox',
    },
    {
      id: 'chargeable',
      displayName: 'Chargeable',
      security: Security.INTERNAL,
      values: [
        {
          id: 'Invoices Chargeable',
          value: 'Invoices Chargeable',
          label: 'Invoices Chargeable',
          isChecked: false,
        },
        {
          id: 'Invoices Not Chargeable',
          value: 'Invoices Not Chargeable',
          label: 'Invoices Not Chargeable',
          isChecked: false,
        },
      ],
      type: 'radio',
    },
    {
      id: 'chargeFeeReport',
      displayName: 'Invoice Created Date',
      security: Security.INTERNAL,
      values: dateFilters,
      type: 'dateRange',
      startExpanded: true,
    },
    {
      id: 'invoice_assignment',
      displayName: 'Invoice Assignee',
      security: Security.INTERNAL,
      values: [
        {
          id: 'Invoices Assigned to Me',
          value: 'Invoices Assigned to Me',
          label: 'Invoices Assigned to Me',
          isChecked: false,
        },
      ],
      type: 'radio',
    },
    // {
    //   id: 'invoice_id',
    //   displayName: 'Invoice ID',
    //   security: Security.INTERNAL,
    //   type: 'search_group',
    //   startExpanded: true,
    //   searchFields: [
    //     {
    //       field: 'invoiceNumber',
    //       placeHolder: '',
    //       label: 'Invoice Number',
    //       values: [],
    //     },
    //   ],
    // },
    {
      id: 'vendor_filters',
      displayName: 'Vendor Attributes',
      security: Security.INTERNAL,
      type: 'search_group',
      startExpanded: true,
      searchFields: [
        {
          field: 'vendorNumber',
          placeHolder: '',
          label: 'Vendor Number',
          values: [],
        },
        {
          field: 'vendorName',
          placeHolder: '',
          label: 'Vendor Name',
          values: [],
        },
      ],
    },
    {
      id: 'assignedUserId',
      displayName: 'Team Member',
      security: Security.INTERNAL,
      values: [],
      type: 'checkbox',
    },
    {
      id: 'invoiceDate',
      displayName: 'Invoice Date',
      security: Security.BOTH,
      values: dateFilters,
      type: 'date',
      startExpanded: false,
    },
    {
      id: 'createDate',
      displayName: 'Create Date',
      security: Security.BOTH,
      values: dateFilters,
      type: 'date',
      startExpanded: false,
    },
    {
      id: 'updateDate',
      displayName: 'Updated Date',
      security: Security.BOTH,
      values: dateFilters,
      type: 'date',
      startExpanded: false,
    },
    {
      id: 'purchase_order_number',
      displayName: 'Purchase Order',
      security: Security.BOTH,
      type: 'search_group',
      startExpanded: false,
      searchFields: [
        {
          field: 'purchaseOrderNumber',
          placeHolder: '',
          label: 'PO #',
          values: [],
        },
      ],
    },
    {
      id: 'location_id',
      displayName: 'Location',
      security: Security.BOTH,
      type: 'search_group',
      startExpanded: false,
      searchFields: [
        {
          field: 'locationId',
          placeHolder: '',
          label: 'Location #',
          values: [],
        },
      ],
    },
    {
      id: 'department_id',
      displayName: 'Department',
      security: Security.BOTH,
      type: 'search_group',
      startExpanded: false,
      searchFields: [
        {
          field: 'departmentId',
          placeHolder: '',
          label: 'Department #',
          values: [],
        },
      ],
    },
  ],
}

export interface FormSelectProp {
  label: string
  value: string
}

export const SearchBarOptions = [
  {
    label: 'Request ID',
    value: 'invoiceId',
  },
  {
    label: 'Invoice Number',
    value: 'invoiceNumber',
  },
  {
    label: 'PO Number',
    value: 'purchaseOrderNumber',
  },
  {
    label: 'Vendor Number',
    value: 'vendorNumber',
  },
]

export const excelDownloadHeaders = [
  { label: 'Invoice ID', key: 'invoiceId', isVendor: true },
  { label: 'Vendor ID', key: 'vendorNumber', isVendor: true },
  { label: 'Vendor Name', key: 'vendorName', isVendor: true },
  { label: 'Vendor Contact Name', key: 'vendorContactName', isVendor: true },
  { label: 'Vendor Email Address', key: 'vendorEmailAddress', isVendor: true },
  { label: 'Status', key: 'status', isVendor: true },
  {
    label: 'Edi Status',
    key: 'ediAndExemptionDetails.ediStatus',
    isVendor: false,
  },
  {
    label: 'Edi Charge Fee Exempted',
    key: 'ediAndExemptionDetails.isEdiChargeFeeExempted',
    isVendor: false,
  },
  {
    label: 'Edi Charge Fee Exemption Reason',
    key: 'ediAndExemptionDetails.ediChargeFeeExemptionReason',
    isVendor: false,
  },
  {
    label: 'Edi Charge Fee Exemption Comment',
    key: 'ediAndExemptionDetails.ediChargeFeeExemptionComment',
    isVendor: false,
  },
  { label: 'Invoice Number', key: 'invoiceNumber', isVendor: true },
  { label: 'Invoice Amount', key: 'invoiceAmount', isVendor: true },
  { label: 'Allowance Amount', key: 'allowanceAmount', isVendor: true },
  { label: 'Invoice Date', key: 'invoiceDate', isVendor: true },
  {
    label: 'Purchase Order Number',
    key: 'purchaseOrderNumber',
    isVendor: true,
  },
  { label: 'Department ID', key: 'departmentId', isVendor: true },
  { label: 'Location ID', key: 'locationId', isVendor: true },
  { label: 'Create User', key: 'createUser', isVendor: true },
  { label: 'Create Date', key: 'createTimestamp', isVendor: true },
  { label: 'Assigned Team Member', key: 'assignedUserName', isVendor: true },
]
//TODO: add update ts, user, lanId - assigned user and new fields

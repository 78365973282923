import { ExemptionVendorView } from './ExemptionVendorView'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import { useExemptionVendor } from '../../api/hooks/useExemptionVendor'
import { Formik } from 'formik'
import { formatVendor } from '../submitInvoice/utils'
import moment from 'moment/moment'
import { addSchema, initialFormValues, isCreateView } from './utils'
import { useAuth } from '@praxis/component-auth'

interface ExemptionVendorViewWrapperProps {
  showCreateScreen: boolean
  setShowCreateScreen: (flag: boolean) => void
  setReloadData: (flag: boolean) => void
  exemptionId: number
}

export const ExemptionVendorViewWrapper: React.FC<
  ExemptionVendorViewWrapperProps
> = ({ showCreateScreen, setShowCreateScreen, setReloadData, exemptionId }) => {
  const makeToast = useToaster()
  const { postVendor, updateVendor } = useExemptionVendor()
  const { session } = useAuth()

  const createExempt = (values: any) => {
    resetDates(values)
    postVendor(formatVendor(values))
      .then((response: any) => {
        setShowCreateScreen(false)
        makeToast({
          autoHideDuration: 5000,
          type: 'success',
          heading: 'Successfully submitted!',
          message: 'Your request has been submitted.',
        })
        onClose()
      })
      .catch((err) => {
        let errorMessage: string
        if (err.status === 400) {
          errorMessage = err.data.message
        } else {
          errorMessage =
            'An error occurred while processing your request. Please try again later.'
        }
        makeToast({
          autoHideDuration: 4000,
          type: 'error',
          heading: 'Request Failed',
          message: errorMessage,
        })
      })
  }

  const updateExempt = (values: any) => {
    resetDates(values)
    const currentDateTime = moment()
    const formattedDateTime = currentDateTime.format('YYYY-MM-DDTHH:mm:ss.SSS')
    let auditEntry = {
      activityDescription: 'updated vendor',
      activityTimestamp: formattedDateTime,
      userId: session?.userInfo?.lanId,
      orderNumber: 1,
      comment: '',
    }
    const newVendor = {
      ...values,
      auditEntry: auditEntry,
    }
    updateVendor(exemptionId, newVendor)
      .then((response: any) => {
        makeToast({
          autoHideDuration: 5000,
          type: 'success',
          heading: 'Successfully submitted!',
          message: 'Your request has been submitted.',
        })
        onClose()
      })
      .catch((error: any) => {
        let errorMessage: string
        if (error.status === 400) {
          errorMessage = error.data.message
        } else {
          errorMessage =
            'An error occurred while processing your request. Please try again later.'
        }
        makeToast({
          autoHideDuration: 4000,
          type: 'error',
          heading: 'Request Failed',
          message: errorMessage,
        })
      })
  }

  const resetDates = (values: any) => {
    if (values.permanentExemptFlag) {
      values.exemptStartDate = ''
      values.exemptEndDate = ''
    }
  }

  const handleSubmitVendor = (newFormValues: any, event: any) => {
    try {
      if (isCreateView(exemptionId)) {
        createExempt(newFormValues)
      } else {
        updateExempt(newFormValues)
      }
    } catch (error: any) {
      let message = error?.response?.data?.message
      makeToast({
        type: 'error',
        message,
        heading: 'Vendor Submission Failed',
      })
    } finally {
      event.setSubmitting(false)
    }
  }

  const onClose = () => {
    setShowCreateScreen(false)
    setReloadData(true)
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialFormValues}
      validationSchema={addSchema}
      onSubmit={handleSubmitVendor}
    >
      <ExemptionVendorView
        showCreateScreen={showCreateScreen}
        setShowCreateScreen={setShowCreateScreen}
        exemptionId={exemptionId}
      />
    </Formik>
  )
}

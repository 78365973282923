import { Layout, Grid, Heading, Button } from '@enterprise-ui/canvas-ui-react'
import {
  CancelIcon,
  CheckIcon,
  EnterpriseIcon,
  PencilIcon,
} from '@enterprise-ui/icons'
import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ExemptionVendorResponse } from '../../api/models/ExemptionVendor'
import { useState, useEffect } from 'react'
import { useExemptionVendor } from '../../api/hooks/useExemptionVendor'
import { ExemptionVendorViewWrapper } from './ExemptionVendorViewWrapper'
import _ from 'lodash'

export const ExemptionVendorList = () => {
  const [vendorList, setVendorList] = useState<ExemptionVendorResponse[]>([])
  const { getAllVendors } = useExemptionVendor()
  const [showCreateScreen, setShowCreateScreen] = useState<boolean>(false)
  const [reloadData, setReloadData] = useState<boolean>(true)
  const [exemptionId, setExemptionId] = useState(0)

  useEffect(() => {
    if (reloadData) {
      getAllVendors()
        .then((response: any) => {
          setVendorList(response.exemptionVendorResponseList)
          setReloadData(false)
        })
        .catch((error: any) => console.log(error))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData])
  const columns: ColDef[] = [
    {
      field: 'exemptionVendorId',
      headerName: 'Action',
      pinned: 'left',
      maxWidth: 100,
      cellRenderer: (props: any) => {
        return (
          <Button
            iconOnly
            aria-label="Edit"
            onClick={() => {
              setShowCreateScreen(true)
              setExemptionId(props.value)
            }}
          >
            <EnterpriseIcon icon={PencilIcon}></EnterpriseIcon>
          </Button>
        )
      },
    },
    {
      field: 'vendorNumber',
      headerName: 'Vendor Number',
      sortable: true,
    },
    {
      field: 'vendorName',
      headerName: 'Vendor Name',
      sortable: true,
      minWidth: 350,
      cellRenderer: (props: any) => {
        return <span>{_.startCase(_.toLower(props.value))}</span>
      },
    },
    {
      field: 'active',
      headerName: 'Active',
      maxWidth: 100,
      cellRenderer: (props: any) => {
        if (props.value === true) {
          return <EnterpriseIcon icon={CheckIcon} color="#32864e" size="lg" />
        }
        return <EnterpriseIcon icon={CancelIcon} color="#ae570f" size="lg" />
      },
    },
    {
      field: 'permanentExemptFlag',
      headerName: 'Permanent Exemption',
      cellRenderer: (props: any) => {
        if (props.value === true) {
          return <EnterpriseIcon icon={CheckIcon} color="#32864e" size="lg" />
        }
        return <EnterpriseIcon icon={CancelIcon} color="#ae570f" size="lg" />
      },
    },
    {
      field: 'exemptStartDate',
      headerName: 'Exemption Start Date',
    },
    {
      field: 'exemptEndDate',
      headerName: 'Exemption End Date',
    },
    {
      field: 'ediStatus',
      headerName: 'Edi Status',
    },
    {
      field: 'updateUser',
      headerName: 'Last Modified By',
    },
  ]
  return (
    <>
      <Layout.SectionHeader>
        <Grid.Container justify="space-between">
          <Grid.Item xs={7}>
            <Heading size={4}>Vendor Exemptions</Heading>
          </Grid.Item>
          <Grid.Item xs={5} style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              onClick={() => {
                setExemptionId(0)
                setShowCreateScreen(true)
                console.log('createScreen:', showCreateScreen)
              }}
            >
              Add Vendors
            </Button>
          </Grid.Item>
        </Grid.Container>
        <Grid.Container style={{ padding: '20px 0' }}>
          <Grid.Item xs={12}>
            <div style={{ flex: '1 1 auto', height: '80vh' }}>
              <AutoSizer>
                {({ height, width }: { height: number; width: number }) => (
                  <div
                    className="ag-theme-alpine"
                    style={{ height: height, width: width }}
                  >
                    <AgGridReact
                      rowData={vendorList}
                      pagination={true}
                      columnDefs={columns}
                    />
                  </div>
                )}
              </AutoSizer>
            </div>
          </Grid.Item>
        </Grid.Container>
        <ExemptionVendorViewWrapper
          showCreateScreen={showCreateScreen}
          setShowCreateScreen={setShowCreateScreen}
          setReloadData={setReloadData}
          exemptionId={exemptionId}
        />
      </Layout.SectionHeader>
    </>
  )
}

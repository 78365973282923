/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState, useEffect, useCallback } from 'react'
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  VisibilityState,
  RowSelectionState,
} from '@tanstack/react-table'
import {
  Grid,
  Pagination,
  Table,
  Form,
  ToastProvider,
  InputCheckbox,
} from '@enterprise-ui/canvas-ui-react'
import { useNavigate } from 'react-router'
import { InvoiceResponse } from '../../api/models/InvoiceResponse'
import { useInvoice } from '../../api/hooks/useInvoice'
import { useDashboard } from '../../context/Invoices/DashboardProvider'
import { useUserProfile } from '../../context/UserProfile/UserProfileProvider'
import { buildQuery } from '../../utils/SearchUtils'
import { useLocation } from 'react-router-dom'
import { Role } from '../../context/UserProfile/UserProfileContext'
import { useAuth } from '@praxis/component-auth'
import LoadingSpinner from '../../globalComponents/LoadingSpinner'
import _ from 'lodash'
import { useQueryClient, useMutation } from 'react-query'
import { ExemptMultipleModal } from './ExemptMultipleModal'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../configs/apiConfig'

interface InvoicesTableProp {
  setSearchQuery: any
  sortColumn: any
  sortAscending: boolean
  isExemptMultipleModalVisible: boolean
  setIsExemptMultipleModalVisible: any
}
export const InvoicesTable: React.FC<InvoicesTableProp> = ({
  setSearchQuery,
  sortColumn,
  sortAscending,
  isExemptMultipleModalVisible,
  setIsExemptMultipleModalVisible,
}) => {
  const fetchIdRef = React.useRef(0)
  const [invoices, setInvoices] = useState<InvoiceResponse[]>([])
  const [data, setData] = useState(invoices)

  const [pageIndex, setPageIndex] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(50)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [totalInvoices, setTotalInvoices] = useState<number>(0)
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    ediAndExemptionDetails_isEdiChargeFeeExempted: false,
    invoiceId: false,
  })
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const [loading, setLoading] = useState<boolean>(false)

  const client = useQueryClient()
  const { isAuthorized, session } = useAuth()
  const { auth } = useEnv() as EnvConfig
  const [dashboard, dispatch] = useDashboard()
  const [userProfile] = useUserProfile()
  const navigate = useNavigate()
  const location = useLocation()
  const makeToast = ToastProvider.useToaster()
  const [dashboardState] = useDashboard()

  const { getInvoicesForAppliedFilters, updateExemptionStatusForIds } =
    useInvoice()

  const handleClose = () => {
    setIsExemptMultipleModalVisible(false)
  }

  const { mutate: exemptMutate, isLoading } = useMutation({
    mutationFn: ({
      multipleExemptionModel,
    }: {
      multipleExemptionModel: any
    }) => {
      return updateExemptionStatusForIds(multipleExemptionModel)
    },
    onSuccess: async (data) => {
      handleClose()
      makeToast({
        autoHideDuration: 4000,
        type: 'success',
        heading: 'Invoices Exempted Successfully',
        message: 'Selected Invoices have been exempted from the Charge Fee',
      })
      await fetchInvoices({ pageSize: pageSize, pageIndex: pageIndex })
      table.resetRowSelection(true)
      client.invalidateQueries({ queryKey: ['getInvoice'] })
    },
    onError: (err) => {
      let message = err
      makeToast({
        autoHideDuration: 6000,
        type: 'error',
        heading: 'Something went wrong!',
        message:
          'Please make sure your connected to network or try again later.',
      })
    },
  })

  const handleExempt = (multipleExemptionModel: any) => {
    exemptMutate({
      multipleExemptionModel,
    })
  }

  const columns = useMemo(
    () => [
      {
        header: (headerProps: any) => (
          <InputCheckbox
            id={'selectAll'}
            checked={headerProps.table.getIsAllRowsSelected()}
            indeterminate={headerProps.table.getIsSomeRowsSelected()}
            onChange={headerProps.table.getToggleAllRowsSelectedHandler()}
          />
        ),
        accessorKey: 'invoiceId',
        size: 1,
        cell: (cellProps: any) => (
          <>
            <div
              style={{ display: 'flex' }}
              onClick={(e: any) => e.stopPropagation()}
            >
              <InputCheckbox
                id={`select_${cellProps?.row.id}`}
                css={{ width: 'auto' }}
                checked={cellProps.row.getIsSelected()}
                onChange={cellProps.row.getToggleSelectedHandler()}
              />
            </div>
          </>
        ),
      },
      {
        header: 'Request ID',
        accessorKey: 'invoiceId',
        size: 1,
      },
      {
        header: 'Invoice #',
        accessorKey: 'invoiceNumber',
        size: 1,
      },
      {
        header: 'Vendor #',
        accessorKey: 'vendorNumber',
        size: 1,
      },
      {
        header: 'Vendor Name',
        accessorKey: 'vendorName',
        size: 1,
      },
      {
        header: 'PO Number',
        accessorKey: 'purchaseOrderNumber',
        size: 1,
      },
      {
        header: 'Department',
        accessorKey: 'departmentId',
        size: 1,
      },
      // {
      //   header: 'Location',
      //   accessorKey: 'locationId',
      //   size: 1,
      // },
      {
        header: 'Invoice Amount',
        accessorKey: 'invoiceAmount',
        size: 1,
      },
      {
        header: 'Invoice Date',
        accessorKey: 'invoiceDate',
        size: 1,
      },
      {
        header: 'Status',
        accessorKey: 'status',
        size: 1,
      },
      {
        header: 'Chargeable',
        accessorKey: 'ediAndExemptionDetails.isEdiChargeFeeExempted',
        size: 1,
        cell: (cellProps: any) => (
          <>
            {cellProps.getValue() !== true
              ? cellProps.getValue() === false
                ? 'Yes'
                : 'NA'
              : 'No'}
          </>
        ),
      },
      {
        header: 'Create Date',
        accessorKey: 'createTimestamp',
        size: 1,
        cell: (cellProps: any) => (
          <>
            <DateFormatter date={cellProps.getValue()} format="YYYY-MM-DD" />
          </>
        ),
      },
    ],
    []
  )

  const table = useReactTable({
    columns,
    data,
    state: {
      columnVisibility,
      rowSelection,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    },
  })

  const actualSelectedInvoices = _.filter(
    table.getSelectedRowModel().flatRows.map((row: any) => row.original),
    (_: any) => {
      const filter = false
      return !filter
    }
  )

  const fetchInvoices = React.useCallback(
    async ({ pageSize, pageIndex }: any) => {
      const fetchId = ++fetchIdRef.current
      if (fetchId === fetchIdRef.current) {
        setLoading(true)
        try {
          let query: any =
            buildQuery(location, dashboard, session?.userInfo?.lanId) ?? {}
          if (
            userProfile?.userRole === Role.VENDOR &&
            query['vendorNumber'] == null
          ) {
            query['vendorNumber'] = userProfile?.vendorList?.map(
              (vendor: any) => vendor?.vendorId
            )
          }

          const response = await getInvoicesForAppliedFilters({
            ...query,
            page: pageIndex + 1,
            perPage: pageSize,
            sortBy: sortColumn?.id,
            sortOrder: sortAscending ? 'ASC' : 'DESC',
          })

          setInvoices(response?.invoiceResponseList ?? [])
          setData(response?.invoiceResponseList ?? [])
          setTotalPages(Math.ceil(response?.totalCount / pageSize))
          setTotalInvoices(response?.totalCount)
          setLoading(false)
          setSearchQuery({
            ...query,
            page: 1,
            perPage: response?.totalCount,
            sortBy: sortColumn?.id,
            sortOrder: sortAscending ? 'ASC' : 'DESC',
          })
        } catch (error) {
          console.log('Error in Fetch Invoices API', error)
          setLoading(false)
          makeToast({
            autoHideDuration: 4000,
            type: 'error',
            heading: 'Error while fetching invoices',
            message:
              'Make sure you are connected to network or try again later',
          })
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      location.search,
      session?.userInfo?.lanId,
      sortColumn?.id,
      sortAscending,
      setSearchQuery,
      userProfile,
    ]
  )

  const toggleChargeableColumnVisibility = useCallback(
    (visible: boolean) => {
      table.setColumnVisibility({
        invoiceId: visible,
        ediAndExemptionDetails_isEdiChargeFeeExempted: visible,
      })
    },
    [table]
  )

  const isInternalUser = useCallback(() => {
    return isAuthorized(auth.tmRole) || isAuthorized(auth.adminRole)
  }, [isAuthorized, auth.tmRole, auth.adminRole])

  useEffect(
    () => {
      if (dashboardState.isReload) {
        setPageIndex(0)
        dispatch({ type: 'RELOAD_INVOICES', isReload: false })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dashboardState.isReload]
  )

  useEffect(() => {
    const selected = table.getSelectedRowModel()
    dispatch({ type: 'SET_ACTION', actionVisible: selected.rows.length !== 0 })
  }, [dispatch, table, rowSelection])

  useEffect(() => {
    if (userProfile !== undefined) {
      if (userProfile.isVendor && userProfile.vendorList) {
        fetchInvoices({ pageSize: pageSize, pageIndex: pageIndex })
      }

      if (!userProfile.isVendor) {
        fetchInvoices({ pageSize: pageSize, pageIndex: pageIndex })
      }
      if (isInternalUser()) {
        toggleChargeableColumnVisibility(true)
      } else {
        toggleChargeableColumnVisibility(false)
      }
    } else {
      toggleChargeableColumnVisibility(false)
    }
  }, [
    userProfile,
    fetchInvoices,
    pageIndex,
    pageSize,
    toggleChargeableColumnVisibility,
    isInternalUser,
  ])

  if (loading) {
    return <LoadingSpinner />
  }
  return (
    <React.Fragment>
      <ExemptMultipleModal
        visible={isExemptMultipleModalVisible}
        invoices={actualSelectedInvoices}
        handleExempt={handleExempt}
        handleClose={handleClose}
        isLoading={isLoading}
      />
      <Grid.Container>
        <Grid.Item xs={12}>
          <Table name="Invoice List">
            <Table.Head>
              <Table.Row>
                {table?.getFlatHeaders()?.map((header) => {
                  return (
                    <Table.Header
                      key={header.id}
                      xs={
                        header.column.id === 'vendorName' && !isInternalUser()
                          ? 3
                          : header.column.columnDef.size
                      }
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </Table.Header>
                  )
                })}
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {table?.getRowModel()?.rows?.map((row) => (
                <Table.Row
                  key={row.id}
                  onClick={() => {
                    navigate(`/view/${row?.original?.invoiceId}`)
                  }}
                >
                  {row?.getVisibleCells()?.map((cell) => {
                    return (
                      <Table.Data
                        xs={
                          cell.column.id === 'vendorName' && !isInternalUser()
                            ? 3
                            : cell.column.columnDef.size
                        }
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Table.Data>
                    )
                  })}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Item>
      </Grid.Container>
      <Grid.Container direction="row-reverse" className="hc-pv-none">
        <Grid.Item className="hc-pb-none">
          <Form.Field
            data-testid="pageSize_select"
            id="pageSize_select"
            value={pageSize}
            onUpdate={(e: any, a: any) => {
              setPageSize(Number(a))
            }}
            type="select"
            options={[
              {
                value: 10,
                label: 'Show 10 Invoices',
              },
              {
                value: 25,
                label: 'Show 25 Invoices',
              },
              {
                value: 50,
                label: 'Show 50 Invoices',
              },
            ]}
          />
        </Grid.Item>
        <Grid.Item>
          <Pagination
            currentPage={pageIndex + 1}
            onRequestFirst={() => {
              table.resetRowSelection(true)
              setPageIndex(0)
            }}
            onRequestPrev={() => {
              table.resetRowSelection(true)
              setPageIndex(pageIndex - 1)
            }}
            onRequestNext={() => {
              table.resetRowSelection(true)
              setPageIndex(pageIndex + 1)
            }}
            onRequestLast={() => {
              table.resetRowSelection(true)
              setPageIndex(totalPages - 1)
            }}
            totalPages={totalPages}
          />
        </Grid.Item>
      </Grid.Container>
    </React.Fragment>
  )
}
//if export toe xcel works with invoices/data remove setSearchQuery

import { useState, useEffect, useRef } from 'react'
import {
  Button,
  Card,
  Dropdown,
  Grid,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import * as qs from 'qs'
import { LandingPageCard } from './LandingPageCard'
import SearchBar from './SearchBar'
import { ExportToExcel } from './ExportToExcel'
import SideFilters from './SideFilters'
import { useDashboard } from '../../context/Invoices/DashboardProvider'
import { useLocation } from 'react-router-dom'
import { InvoicesTable } from './InvoicesTable'
import { useInvoice } from '../../api/hooks/useInvoice'
import { excelDownloadHeaders } from '../../constants/ExcelColumns'
import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../configs/apiConfig'
import { getCompactNumber } from '../../utils/Formatter'
import EnterpriseIcon, {
  ArrowDownIcon,
  ArrowUpIcon,
} from '@enterprise-ui/icons'
import { useAuth } from '@praxis/component-auth'

export interface CardConfig {
  count?: number
  status?: string
  text?: string
}

interface DashboardProps {
  sideFilterVisible?: any
}

const sortColumns = [
  {
    id: 'createTimestamp',
    value: 'Create TimeStamp',
  },
  {
    id: 'updateTimestamp',
    value: 'Update TimeStamp',
  },
  {
    id: 'status',
    value: 'Status',
  },
]

export const DashboardSection: React.FC<DashboardProps> = ({
  sideFilterVisible,
}) => {
  const { isAuthorized } = useAuth()
  const { auth } = useEnv() as EnvConfig
  const env = useEnv() as EnvConfig
  const makeToast = ToastProvider.useToaster()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = useDashboard()
  const [searchQuery, setSearchQuery] = useState<any>()
  const location = useLocation()
  const csvLink = useRef<any | null>(null)
  const { getInvoicesForAppliedFilters } = useInvoice()

  const [excelData, setExcelData] = useState<{ data: any; loading: boolean }>({
    data: [],
    loading: false,
  })
  const [sortColumn, setSortColumn] = useState<any>({
    id: 'createTimestamp',
    value: 'Create TimeStamp',
  })
  const [sortAscending, setSortAscending] = useState<boolean>(false)
  const [isExemptMultipleModalVisible, setIsExemptMultipleModalVisible] =
    useState<boolean>(false)

  const isInternalUser = (): Boolean => {
    return isAuthorized(auth.tmRole) || isAuthorized(auth.adminRole)
  }

  const isVendorUser = (): Boolean => {
    return isAuthorized(auth.vendorRole)
  }

  const getExcelHeaders = () => {
    if (isInternalUser()) return excelDownloadHeaders
    else {
      return excelDownloadHeaders.filter(
        (header) => header.isVendor === isVendorUser()
      )
    }
  }

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FILTERS',
      activeFilters: qs.parse(location.search.replace('?', ''), {
        arrayLimit: 100,
      }),
    })
  }, [dispatch, location.search])

  async function handleExcelDownload() {
    setExcelData({ ...excelData, loading: true })

    const maxSize = env.download.downloadSize
    const maxCmptSize = getCompactNumber(maxSize, 0)
    if (searchQuery?.perPage > maxSize) {
      makeToast({
        autoHideDuration: 4000,
        type: 'error',
        heading: `Download size is greater than ${maxCmptSize}!`,
        message: `Only ${maxCmptSize} invoices will be exported to excel.`,
      })
    }

    await getInvoicesForAppliedFilters({ ...searchQuery, perPage: maxSize })
      .then((data: any) => {
        setExcelData({
          ...excelData,
          data: data?.invoiceResponseList ?? [],
          loading: false,
        })
        if (data?.invoiceResponseList?.length > 0) {
          setTimeout(() => {
            csvLink?.current?.link.click()
          })
        }
      })
      .catch((err: any) => {
        console.log('Error while exporting to excel', err)
        setExcelData({ ...excelData, loading: false })
        makeToast({
          autoHideDuration: 4000,
          type: 'error',
          heading: 'Error while exporting to excel',
          message: 'Make sure you are connected to network or try again later',
        })
      })
  }

  return (
    <>
      <Grid.Container className="hc-pt-sm">
        <Grid.Item xs>
          <LandingPageCard />
          <Grid.Container direction="row-reverse" className="hc-pt-sm">
            {state.actionVisible && isInternalUser() && (
              <>
                <Grid.Item>
                  <Dropdown location="bottom-right">
                    <Button type="secondary">Actions</Button>
                    <Dropdown.Menu>
                      <Dropdown.MenuItem
                        onClick={() => {
                          setIsExemptMultipleModalVisible(true)
                        }}
                      >
                        Exempt Charge Fee
                      </Dropdown.MenuItem>
                    </Dropdown.Menu>
                  </Dropdown>
                </Grid.Item>
              </>
            )}
            {!state.actionVisible && (
              <>
                <Grid.Item>
                  <Dropdown>
                    <Button type="secondary" className="hc-mr-xs">
                      <span className="hc-clr-grey01 hc-fs-caption">
                        Sort by:{' '}
                      </span>
                      &nbsp;
                      {sortColumn.value}
                    </Button>
                    <Dropdown.Menu>
                      {sortColumns.map((sort) => (
                        <Dropdown.MenuItem
                          key={sort.id}
                          onClick={() => setSortColumn(sort)}
                        >
                          {sort.value}
                        </Dropdown.MenuItem>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button
                    onClick={() => {
                      setSortAscending((sort: any) => !sort)
                    }}
                    type="secondary"
                    aria-label="Sort"
                    className="hc-mr-xs"
                  >
                    <span className="hc-fs-caption">Sort by: </span>
                    &nbsp;
                    <EnterpriseIcon
                      icon={sortAscending ? ArrowDownIcon : ArrowUpIcon}
                    />
                  </Button>
                </Grid.Item>
                <Grid.Item>
                  <ExportToExcel
                    onClick={handleExcelDownload}
                    columns={getExcelHeaders()}
                    data={excelData.data ?? []}
                    loading={excelData.loading}
                    csvLink={csvLink}
                  />
                </Grid.Item>
              </>
            )}
            <Grid.Item xs></Grid.Item>
            <SearchBar />
          </Grid.Container>
          <Card className="hc-pa-lg hc-mt-none">
            <InvoicesTable
              setSearchQuery={setSearchQuery}
              sortAscending={sortAscending}
              sortColumn={sortColumn}
              isExemptMultipleModalVisible={isExemptMultipleModalVisible}
              setIsExemptMultipleModalVisible={setIsExemptMultipleModalVisible}
            />
          </Card>
        </Grid.Item>
        {sideFilterVisible && (
          <>
            <Grid.Item xs={2}>
              <SideFilters />
            </Grid.Item>
          </>
        )}
      </Grid.Container>
    </>
  )
}
export default DashboardSection

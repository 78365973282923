import React from 'react'
import {
  ExpandableSection,
  Heading,
  Chip,
  Button,
} from '@enterprise-ui/canvas-ui-react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { SideFilterProp } from '../../../constants/SearchFilterInitialValues'
import { convertToUS, filterUser } from '../../../utils/SearchUtils'
// import { SideFilter } from '../SearchContext/SearchInitialValues'
// import { convertToUS, filterUser } from '../SearchContext/SearchUtils'

const filterHeight = css`
  max-height: 170px;
  overflow-y: auto;
`

interface ActiveSearchFiltersProps {
  sideFilters?: SideFilterProp[]
  searchFilters?: [any]
  handleClearSingleFilter: any
  handleClearFilters: any
  vendor?: boolean
}

interface ActiveFilter {
  filter: string
  id: string
  value: string
  type:
    | 'checkbox'
    | 'search_group'
    | 'date'
    | 'searchFilter'
    | 'radio'
    | 'dateRange'
  sideFilter?: string
}

export const ActiveSearchFilters: React.FC<ActiveSearchFiltersProps> = ({
  sideFilters,
  searchFilters,
  handleClearSingleFilter,
  handleClearFilters,
  vendor,
}) => {
  const buildFilters = () => {
    const filters: ActiveFilter[] = []

    searchFilters?.forEach((obj: any) => {
      obj.value.forEach((val: any) => {
        filters.push({
          filter: 'search',
          id: obj.id,
          value: val,
          type: 'searchFilter',
        })
      })
    })

    sideFilters
      ?.filter((key) => filterUser(key, vendor))
      .forEach((filter) => {
        if (filter.type === 'checkbox' || filter.type === 'radio') {
          filter.values?.forEach((value) => {
            if (value.isChecked) {
              filters.push({
                filter: filter.id,
                id: value.id,
                value: value.label,
                type: filter.type,
              })
            }
          })
        }
        if (filter.type === 'date' && filter.active) {
          console.log('date filters', filter)
          const label = filter
            .values!.filter(
              (value) =>
                JSON.stringify(filter.active) === JSON.stringify(value.value)
            )
            .map((value) => value.label)
            .join('')

          filters.push({
            filter: filter.id,
            id: filter.id + filter.active,
            value:
              label.length > 0
                ? label
                : `${convertToUS(filter.active.from)} to ${convertToUS(
                    filter.active.to
                  )}`,
            type: 'date',
          })
        }
        if (filter.type === 'dateRange' && filter.active) {
          console.log('dateRange filters', filter)
          const label = filter
            .values!.filter(
              (value) =>
                JSON.stringify(filter.active) === JSON.stringify(value.value)
            )
            .map((value) => value.label)
            .join('')

          filters.push({
            filter: filter.id,
            id: filter.id + filter.active,
            value:
              label.length > 0
                ? label
                : `${convertToUS(filter.active.from)} to ${convertToUS(
                    filter.active.to
                  )}`,
            type: 'dateRange',
          })
        }
        if (filter.type === 'search_group') {
          filter.searchFields?.forEach((field) => {
            field.values?.forEach((value) => {
              filters.push({
                filter: field.field,
                id: value.id,
                value: value.label,
                type: 'search_group',
              })
            })
          })
        }
      })
    return filters
  }

  const activeFilters = buildFilters()

  if (activeFilters.length === 0) {
    return null
  }

  return (
    <ExpandableSection padding="dense" startExpanded toggleLocation="left">
      <Heading className="hc-clr-black" size={6}>
        Active Filters
      </Heading>

      <ExpandableSection.Content className="hc-ml-sm hc-mr-sm">
        <div css={filterHeight}>
          {activeFilters.map((filter) => {
            return (
              <Chip
                key={`${filter.filter}-${filter.id}`}
                size="dense"
                color="alert"
                onRequestDelete={() => {
                  handleClearSingleFilter(
                    filter.filter,
                    filter.id,
                    filter.type,
                    filter.sideFilter,
                    filter.value
                  )
                }}
                style={{ backgroundColor: '#edb508' }}
                className="hc-mb-dense hc-clr-contrast-weak"
              >
                {filter.value}
              </Chip>
            )
          })}
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button size="dense" onClick={() => handleClearFilters()}>
            Clear Filters
          </Button>
        </div>
      </ExpandableSection.Content>
    </ExpandableSection>
  )
}
